import React from 'react';
import Img from 'gatsby-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import styles from './Article.module.scss';

const Article = ({
  article: {
    title,
    metaDescription,
    date,
    slug,
    fluid,
  },
}) => (
  <div className={styles.Article}>
    <AnchorLink
      to={slug}
      title={title}
    >
      <Img fluid={fluid} />
    </AnchorLink>
    <AnchorLink
      to={slug}
      title={title}
    >
      <h2>{title}</h2>
    </AnchorLink>
    <p>{metaDescription}</p>
  </div>
);

export default Article;
