import React from 'react';

import { graphql } from 'gatsby';

import styles from './blogListingTemplate.module.scss';

import Layout from '../layouts';
import Article from '../pageComponents/blog/listingsArticle/Article';

const BlogListingTemplate = ({
  pageContext,
  data,
}) => {
  console.log('page Context - ', pageContext);

  const { articles } = pageContext;

  const withFluidImage = (article) => {
    const fileName = article.headerImage.src.split('/').pop();
    const imageSharp = data.listingImages.nodes
      .find(({ childImageSharp }) => {
        const src = (childImageSharp && childImageSharp.fluid && childImageSharp.fluid.src) || '';
        return new RegExp(fileName).test(src);
      });
    const fluid = imageSharp ? imageSharp.childImageSharp.fluid : null;
    return { ...article, fluid };
  };

  return (
    <Layout>
      <div className={styles.Container}>
        {
          articles.map(((article, idx) => {
            const k = `${idx}ListingCard`;
            return (
              <Article article={withFluidImage(article)} key={k} />
              // <Ar>
              //   <Img fluid={withFluidImage(article).fluid} />
              //   <h1>{article.title}</h1>
              // </Article>
            );
          }))
        }
      </div>

    </Layout>
  );
};

export default BlogListingTemplate;

export const query = graphql`
  query BlogListingImagesQuery($listingImageSources: [String]) {
    listingImages: allFile(filter: { relativePath: { in: $listingImageSources } }) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 360, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
